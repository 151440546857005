import { FaEdit } from "react-icons/fa";
import './Intro.scss'
import gardener from '../../assets/logo/gardener.png'
import tree from '../../assets/logo/tree.jpg'
import { useEffect, useState } from "react";

function Intro({ token, setModalContent, introImage, secondIntroImage, texts}){

    const [background, setBackground] = useState(false);

    function handleEditClick(){
        setModalContent(1);
    }
    function handleSecondEditClick(){
        setModalContent(2);
    }

    const width = window.innerWidth;

    useEffect(() => {
        if(width < 700){
            setBackground(true)
        }
    //eslint-disable-next-line
    },[]);

    return (
        <div className="intro">
            <div className="first-block" style={{ backgroundImage: `url(${introImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                {/* <img src={introImage} alt="" /> */}
                <div className="content">
                    <div className="text">
                        <h1>{texts.find(text => text.cat === 'title')?.text}</h1>
                        <span className="grey-line"></span>
                        <h2>{texts.find(text => text.cat === 'subTitle')?.text}</h2>
                    </div>
                    <a href="#garden">
                        <div className="more-btn">
                            <span className="btn-text">Découvrir</span>
                        </div>
                    </a>
                </div>
                {
                    token &&
                    <div className="edit-button" onClick={handleEditClick}>
                        <FaEdit size={'2em'}/>
                    </div>
                }
            </div>
            <div className="split-bar" id="garden"></div>
            <div className="second-block" style={{background: background ? 'var(--green2)' : `url(${secondIntroImage})`,  backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                {/* <img src={secondIntroImage} alt="" /> */}
                <div className="introduction">
                    <div className="about">
                        <img src={gardener} alt="" className="garden-pic"/>
                        <h1>Qui sommes nous ?</h1>
                        <p>{texts.find(text => text.cat === 'about')?.text}</p>
                    </div>
                    <div className="values">
                        <img src={tree} alt="" />
                        <h1>Nos valeurs</h1>
                        <p>{texts.find(text => text.cat === 'values')?.text}</p>

                    </div>
                </div>
                {
                    token &&
                    <div className="edit-button" onClick={handleSecondEditClick}>
                        <FaEdit size={'2em'}/>
                    </div>
                }
            </div>
        </div>
    )
}

export default Intro