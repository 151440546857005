import './Loading.scss';
import background from '../../assets/jardin-erable.webp'

function Loading() {
    return (
        <div className="loading-container">
            <img src={background} alt="" />
            <div className="content">
                <h1>Bienvenue<br/> les portes vont s'ouvrir</h1>
                <div className='loader'></div>
            </div>
        </div>
    )
}

export default Loading