import GalleryModal from '../gallerymodal/GalleryModal';
import './Gallery.scss'
import { FaEdit } from "react-icons/fa";

function Gallery({token, setModalContent, galleryImages, setOpenGalModal, openGalModal,texts, setElementId, elementId, closeModal}){

    function handleEditClick(){
        setModalContent(4);
    }

    return(
        <div className="gallery" id='gallery'>
            <div className="text">
                <p>{texts.find(text => text.cat === 'gallery')?.text}</p>
            </div>
            <div className="gallery-container">
            { 
                galleryImages.map((image) => (
                    <div className="card">
                        <img key={image._id} src={image.url} alt='gal' onClick={() => {setOpenGalModal(true); setElementId(image._id)}}/>
                    </div>
                ))   
            }            
            </div>
            {
                token &&
                <div className="edit-button" onClick={handleEditClick}>
                    <FaEdit size={'2em'}/>
                </div>
            }
            {
                openGalModal && 
                <GalleryModal elementId={elementId} galleryImages={galleryImages} closeModal={closeModal}/>
            }
        </div>
    )
}

export default Gallery