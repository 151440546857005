import './Header.scss';

function Header() {
    return (
        <div className="header">
            <div className="nav-links">
                <a className='link' href="#garden"><span>Les jardins</span></a>
                    <div className="link menu-wrapper">
                        <a href="#gallery">
                            <span className='menu-parent '>En images</span>
                        </a>
                        {/* <ul className="dropdown-list">
                            <a href="#gallery"><li><span>Galerie</span></li></a>
                            <a href="#slider"><li><span>4 Saisons</span></li></a>
                        </ul> */}
                        </div>
                <a className='link' href="#events"><span>Évènements</span></a>
                <a className='link' href="#contact"><span>Accès - Contact</span></a>
            </div>
        </div>
    );
}

export default Header;
