import './EditModal.scss';
import React, { useState, useEffect } from 'react';
import { IoIosCloseCircle } from "react-icons/io";

function EditModal({
  closeModal, openModal, editImage, modalContent, deleteImage, addImage, editTexts, editPress, setImageUrl, imageUrl, selectedFile, setSelectedFile,
  category, slideImage, galleryImages, newText, setNewText, newTextBis, setNewTextBis, introTitleId, introSubId, aboutTextId,
  valuesTextId, galTextId, texts, addEvent, editEvents
}) {
  const [introTitle, setIntroTitle] = useState('');
  const [introSubTitle, setIntroSubTitle] = useState('');
  const [aboutText, setAboutText] = useState('');
  const [valuesText, setValuesText] = useState('');
  const [galText, setGalText] = useState('');

  useEffect(() => {
    setIntroTitle(texts.find(text => text.cat === 'introTitle')?.text || '');
    setIntroSubTitle(texts.find(text => text.cat === 'introSubTitle')?.text || '');
    setAboutText(texts.find(text => text.cat === 'about')?.text || '');
    setValuesText(texts.find(text => text.cat === 'values')?.text || '');
    setGalText(texts.find(text => text.cat === 'gallery')?.text || '');
  }, [texts]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const url = URL.createObjectURL(file);
      setImageUrl(url);
    }
  };

  const handleAdd = () => {
    const updatedTexts = [];

    if (modalContent === 3) {
      addImage(selectedFile, category);
    }

    if (modalContent === 5) {
      const articleText = document.getElementsByName('pressArticle')[0].value;
      const authorText = document.getElementsByName('pressAuthor')[0].value;
      const dateText = document.getElementsByName('pressDate')[0].value;
      const link = document.getElementsByName('pressLink')[0].value;
      editPress(
        articleText !== '' ? articleText : undefined,
        authorText !== '' ? authorText : undefined,
        dateText !== '' ? dateText : undefined,
        link !== '' ? link : undefined
      );
    } else if (modalContent === 6) {
      const titleText = document.getElementsByName('eventTitle')[0].value;
      const dateText = document.getElementsByName('eventDate')[0].value;
      editEvents(
        titleText !== '' ? titleText : undefined,
        dateText !== '' ? dateText : undefined,
        selectedFile !== null ? selectedFile : undefined
      );
    } else {
      if (modalContent === 1) {
        if (introTitle !== '' || introSubTitle !== '') {
          if (introTitle !== '') {
            updatedTexts.push({ text: introTitle, id: introTitleId });
          }
          if (introSubTitle !== '') {
            updatedTexts.push({ text: introSubTitle, id: introSubId });
          }
        }
        if (selectedFile) {
          editImage(selectedFile, category);
        }
      }
      if (modalContent === 2) {
        if (aboutText !== '' || valuesText !== '') {
          if (aboutText !== '') {
            updatedTexts.push({ text: aboutText, id: aboutTextId });
          }
          if (valuesText !== '') {
            updatedTexts.push({ text: valuesText, id: valuesTextId });
          }
        }
        if (selectedFile) {
          editImage(selectedFile, category);
        }
      }
      if (modalContent === 4) {
        if (galText !== '') {
          updatedTexts.push({ text: galText, id: galTextId });
        }
        if (selectedFile) {
          addImage(selectedFile, category);
        }
      }
      editTexts(updatedTexts);
      setNewText('');
      setNewTextBis('');
    }
  };

  return (
    <div>
      {openModal &&
        <div className="modal-container">
          <div className="modal-window">
            <div className="modal-head">
              <IoIosCloseCircle className='close-btn' size={'2em'} onClick={closeModal} />
            </div>
            {modalContent === 1 &&
              <div className="modal-content">
                <label>Changer l'image de fond</label>
                <input type="file" name='image' className='file-loader' onChange={handleFileChange} />
                <img src={imageUrl} alt="" className='first-img' />
                <label>Changer le titre</label>
                <textarea
                  name="title"
                  id={introTitleId}
                  value={introTitle}
                  onChange={(e) => setIntroTitle(e.target.value)}
                />
                <label>Changer le sous-titre</label>
                <textarea
                  name="subTitle"
                  id={introSubId}
                  value={introSubTitle}
                  onChange={(e) => setIntroSubTitle(e.target.value)}
                />
                <button className='submit-btn' onClick={handleAdd}>Enregistrer</button>
              </div>
            }
            {modalContent === 2 &&
              <div className="modal-content">
                <label>Changer l'image de fond</label>
                <input type="file" name='image' className='file-loader' onChange={handleFileChange} />
                <img src={imageUrl} alt="" className='second-img' />
                <label>Changer le texte "Qui sommes nous ?"</label>
                <textarea
                  name='introText'
                  id={aboutTextId}
                  value={aboutText}
                  onChange={(e) => setAboutText(e.target.value)}
                />
                <label>Changer le texte "Nos valeurs"</label>
                <textarea
                  name='introText2'
                  id={valuesTextId}
                  value={valuesText}
                  onChange={(e) => setValuesText(e.target.value)}
                />
                <button className='submit-btn' onClick={handleAdd}>Enregistrer</button>
              </div>
            }
            {modalContent === 3 &&
              <div className="modal-content">
                <label>Changer les images du slider</label>
                <input type="file" name='image' onChange={handleFileChange} />
                <img src={imageUrl} alt="" className='first-img' />
                <div className="slider-grid">
                  {slideImage.map((image, index) => (
                    <div className="image-card" key={index}>
                      <img src={image.url} alt='slider' />
                      <IoIosCloseCircle className='delete-btn' size={'1em'} onClick={() => deleteImage(image._id)} />
                    </div>
                  ))}
                </div>
                <button className='submit-btn' onClick={handleAdd}>Enregistrer</button>
              </div>
            }
            {modalContent === 4 &&
              <div className="modal-content">
                <label>Changer les images de la galerie</label>
                <input type="file" name='image' onChange={handleFileChange} />
                <img src={imageUrl} alt="" className='first-img' />
                <div className="gallery-grid">
                  {galleryImages.map((image, index) => (
                    <div className="image-card" key={index}>
                      <img src={image.url} alt='gallery' />
                      <IoIosCloseCircle className='delete-btn' size={'1em'} onClick={() => deleteImage(image._id)} />
                    </div>
                  ))}
                </div>
                <label>Changer le texte</label>
                <textarea
                  name='galText'
                  id={galTextId}
                  value={galText}
                  onChange={(e) => setGalText(e.target.value)}
                />
                <button className='submit-btn' onClick={handleAdd}>Enregistrer</button>
              </div>
            }
            {modalContent === 5 &&
              <div className="modal-content">
                <label>Changer le contenu de l'article</label>
                <textarea name='pressArticle' />
                <label>Changer l'auteur</label>
                <textarea name='pressAuthor' />
                <label>Changer la date</label>
                <textarea name='pressDate' />
                <label>Changer le lien</label>
                <textarea name='pressLink' />
                <button className='submit-btn' onClick={handleAdd}>Enregistrer</button>
              </div>
            }
            {modalContent === 6 &&
              <div className="modal-content">
                <label>Changer l'image de fond</label>
                <input type="file" name='image' className='file-loader' onChange={handleFileChange} />
                <img src={imageUrl} alt="" className='second-img' />
                <label>Changer le nom</label>
                <textarea
                  name='eventTitle'
                  onChange={(e) => setNewText(e.target.value)}
                />
                <label>Changer la date</label>
                <textarea
                  name='eventDate'
                  onChange={(e) => setNewTextBis(e.target.value)}
                />
                <button className='submit-btn' onClick={handleAdd}>Enregistrer</button>
              </div>
            }
          </div>
        </div>
      }
    </div>
  );
}

export default EditModal;
