import './GalleryModal.scss';
import { IoIosCloseCircle } from "react-icons/io";


function GalleryModal({closeModal, galleryImages, elementId}){

    return(
        <div className="gallery-modal">
                <div className="modal-window">
                    <div className="modal-head">
                        <IoIosCloseCircle className='close-btn' size={'2em'} onClick={() => closeModal()} />
                    </div>
                    <div className="modal-content">
                        <img src={galleryImages.find(image => image._id === elementId)?.url} alt="" className='first-img'/>
                 </div>
        </div>
        </div>
    )
}

export default GalleryModal