import './Events.scss'
import { FaEdit } from "react-icons/fa";
import newspaper from '../../assets/logo/newspaper.png'
import megaphone from '../../assets/logo/megaphone.png'

function Events({presses, events, token, setModalContent, setElementId}){

    function handlePressEdit(){
        setModalContent(5);
    }
    function handleEventEdit(){
        setModalContent(6);
    }

    return(
        <div className="events-press" id='events'>
            <div className="events">
                <div className="title">
                    <h1>Évènements</h1>
                    <img src={megaphone} alt="icone de mégaphone" />
                </div>
                <div className="events-grid">
                    { events.map(event => (
                        <div className="event" key={event._id}>
                            <div className="event-text">
                                <h2>{event.title}</h2>
                                <p>{event.date}</p>
                            </div>
                            <img src={event.url} alt="" />
                            {
                                token &&
                                <div className="edit-button" onClick={() => {handleEventEdit(); setElementId(event._id); }}>
                                    <FaEdit size={'2em'}/>
                                </div>
                            }
                        </div>
                    ))  
                    }

                </div>
            </div>
            <div className="press">
                <div className="title">
                    <h1>Presse</h1>
                    <img src={newspaper} alt="icone de journal" />
                </div>
                    <div className="sub-press-grid">
                        { presses.map((press) => (
                                <div className="article" key={press._id}>
                                {
                                    token &&
                                    <div className="edit-button" onClick={() => { handlePressEdit(); setElementId(press._id); }}>
                                        <FaEdit size={'2em'}/>
                                    </div>
                                }
                                    <a href={press.link} rel='noreferrer noopener' target='_blank'>
                                        <div className="text">
                                            <span>{press.article}</span>
                                            <span className='author'>{press.author}</span>
                                            <span className='date'>{press.date}</span>
                                        </div>
                                    </a>
                                </div>
                        ))  
                        }
                    </div>
            </div>
        </div>
    )
}

export default Events