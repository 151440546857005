import '../pages-style/Home.scss'

import Header from '../components/header/Header'

import Intro from "../components/intro/Intro"
import Gallery from "../components/gallery/Gallery";
import EditModal from "../components/editModal/EditModal";
import Contact from "../components/contact/Contact";
import Events from "../components/press/Events";
import Loading from '../components/loading/Loading';

import { FaArrowCircleUp } from "react-icons/fa";


import { useState, useEffect } from "react";
import axios from "axios";
import { API_ROUTES } from "../utils/constants";

function Home(){

    const token = sessionStorage.getItem('token')

    const [isLoading, setIsLoading] = useState(true); // Used to display or not the waiting screen

    const [openModal, setOpenModal] = useState(false)
    const [openGalModal, setOpenGalModal] = useState(false)
    const [modalContent, setModalContent] = useState(0)
    const [imageUrl, setImageUrl] = useState('')
    const [introId, setIntroId] = useState('')
    // eslint-disable-next-line
    const [secondIntroId, setSecondIntroId] = useState('')

    const [selectedFile, setSelectedFile] = useState(null);
    const [elementId, setElementId] = useState('');

    const [introImage, setIntroImage] = useState('')
    const [secondIntroImage , setSecondIntroImage] = useState('')
    const [slideImage, setSlideImage] = useState([])
    const [galleryImages, setGalleryImages] = useState([])
    // eslint-disable-next-line
    const [introTitle, setIntroTitle] = useState('')
    const [introTitleId, setIntroTitleId] = useState('')
    // eslint-disable-next-line 
    const [introSub, setIntroSub] = useState('')
    const [introSubId, setIntroSubId] = useState('')
    // eslint-disable-next-line
    const [aboutText, setAboutText] = useState('')
    const [aboutTextId, setAboutTextId] = useState('')
    // eslint-disable-next-line
    const [valuesText, setValuesText] = useState('')
    const [valuesTextId, setValuesTextId] = useState('')
    // eslint-disable-next-line
    const [galText, setGalText] = useState('')
    const [galTextId, setGalTextId] = useState('')


    const [events, setEvents] = useState([])
    const [presses, setPresses] = useState([])
    const [texts, setTexts] = useState([])


    const [newText, setNewText] = useState('')
    const [newTextBis, setNewTextBis] = useState('')

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
        });
    };
    
    function closeModal(){
        setModalContent(0)
        setOpenGalModal(false)
        setSelectedFile(null)
        setImageUrl('')
        setElementId('')
    }

    // Checks if modal must open or not
    useEffect(() => {
        if(modalContent !== 0) {
            setOpenModal(true)
        } else {
            setOpenModal(false)
        }
    }, [modalContent]);

    /////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////
    // Images functions

    async function getImages() {
        try {
            const response = await axios.get(`${API_ROUTES.IMAGES}`);
        const imagesData = response.data.images;

        // Utiliser Promise.all pour paralléliser la conversion en base64
        const fetchedImages = await Promise.all(imagesData.map(async (image) => {
            const base64Image = await arrayBufferToBase64(image.data.data);
            const imageUrl = `data:${image.contentType};base64,${base64Image}`;
            return {
                ...image,
                url: imageUrl
            };
        }));
    
            setSlideImage([]);
            setGalleryImages([]);
    
            fetchedImages.forEach(image => {
                const imageCategory = image.cat;
                switch (imageCategory) {
                    case 1:
                        setIntroImage(image.url);
                        setIntroId(image._id);
                        break;
                    case 2:
                        setSecondIntroImage(image.url);
                        setSecondIntroId(image._id);
                        break;
                    case 3:
                        setSlideImage(prevSlideImage => [...prevSlideImage, image]);
                        break;
                    case 4:
                        setGalleryImages(prevGalleryImages => [...prevGalleryImages, image]);
                        break;
                    default:
                        return;
                }
            });
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    }
    
    function arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }   

    async function addImage(selectedFile, category) {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('image', selectedFile);
            bodyFormData.append('category', category); // Ajouter la catégorie à l'objet FormData
        
            // eslint-disable-next-line
            const response = await axios({
                method: 'post',
                url: `${API_ROUTES.IMAGES}`,
                data: bodyFormData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            })
        // Update imageUrl if success
        setImageUrl(imageUrl);
        getImages()
        setImageUrl('');
        }
        catch (error){
            console.error('Error while sending image : ', error)
        }
    }

    async function deleteImage(imageId) {
        try{
            // eslint-disable-next-line
            const response = await axios({
                method: 'delete',
                url: `${API_ROUTES.IMAGES}`,
                data: {imageId},
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            getImages()
        }
        catch(error){
            console.error('Erreur lors de la suppression : ', error);
        }
    }

    async function editImage(selectedFile, category){
        let imageId = '';
            if (category === 1) {
                imageId = introId;
            } else if (category === 2) {
                imageId = secondIntroId;
            }

        try {
            const bodyFormData = new FormData();
            bodyFormData.append('image', selectedFile);
            bodyFormData.append('imageId', imageId); // Ajoutez l'identifiant de l'image dans le corps de la requête
            // eslint-disable-next-line
            const response = await axios({
                method: 'put',
                url: `${API_ROUTES.IMAGES}`,
                data: bodyFormData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            })
            // Recharger le contenu après la modification
            getImages();
            closeModal()
        }
        catch (error){
            console.error('Erreur lors de la modification de l\'image : ', error)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////
    // Text functions

    async function getTexts() {
        try {
            const response = await axios({
                method: 'get',
                url: `${API_ROUTES.TEXT}`,
            });

            const fetchedText = response.data.text

            setTexts(fetchedText)

            fetchedText.forEach(text => {
                const textCategory = text.cat;
                const textId = text._id
                switch (textCategory){
                    case 'title':
                    setIntroTitleId(textId)
                    break;
                case 'subTitle':
                    setIntroSubId(textId);
                    break;
                case 'about':
                    setAboutTextId(textId);
                    break;
                case 'values':
                    setValuesTextId(textId);
                    break;
                case 'gallery':
                    setGalTextId(textId);
                    break;
                default:
                    return;     
                }
                });
                }
                catch(error){
                    console.error('Error fetching text:', error);
                }
    };

    useEffect(() => {
        getTexts()
    },[])

    async function editTexts(updatedTexts) {
        
        try {
            // eslint-disable-next-line
            const responses = await Promise.all(updatedTexts.map(({ text, id }) => {
                return axios({
                    method: 'put',
                    url: `${API_ROUTES.TEXT}`,
                    data: {
                        textId: id,
                        text: text,
                    },
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                });
            }));
            getTexts();
            closeModal();

        } catch (error) {
            console.error('Error while sending text : ', error)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////
    // Press functions

    async function getPress() {
        try {
            const response = await axios({
                method: 'get',
                url: `${API_ROUTES.PRESS}`,
            });

            const fetchedPress = response.data.press

            setPresses(fetchedPress)
            }
            catch(error){
                console.error('Error fetching text:', error);
            }
    };

    async function editPress(article, author, date, link) {
        try {
            // eslint-disable-next-line
                const response = await axios ({
                    method: 'put',
                    url: `${API_ROUTES.PRESS}`,
                    data: {
                        pressId: elementId,
                        article,
                        author,
                        date,
                        link,
                    },
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                })
                getPress();
                closeModal();
            }
            catch (error) {
                console.error('Error while sending presses : ', error)
            }
    }

    ///////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////
    // Events functions

    async function addEvent(selectedFile, title, date) {

        try {
            const bodyFormData = new FormData();
            bodyFormData.append('image', selectedFile);
            bodyFormData.append('eventTitle', title);
            bodyFormData.append('eventDate', date);
        
            // eslint-disable-next-line
            const response = await axios({
                method: 'post',
                url: `${API_ROUTES.EVENTS}`,
                data: bodyFormData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            })
        // Update imageUrl if success
        setImageUrl(imageUrl);
        getImages()
        }
        catch (error){
            console.error('Error while sending image : ', error)
        }
    }

    async function getEvents() {
        try {
            const response = await axios({
                method: 'get',
                url: `${API_ROUTES.EVENTS}`,
            });
    
            const fetchedEvents = response.data.events.map(event => {
                if (event.data && event.contentType) {
                    const base64Image = arrayBufferToBase64(event.data.data); // Convertir les données binaires en base64
                    const imageUrl = `data:${event.data.contentType};base64,${base64Image}`;
                    return {
                        ...event,
                        url: imageUrl, // Ajouter l'URL de l'image encodée
                    };
                }
                return event;
            });
    
            setEvents(fetchedEvents);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    }    

    async function editEvents(title, date, selectedFile) {
        try {

            const bodyFormData = new FormData();
            if (selectedFile) {
                bodyFormData.append('image', selectedFile);
            }
            if (title) {
                bodyFormData.append('title', title);
            }
            if (date) {
                bodyFormData.append('date', date);
            }
            bodyFormData.append('eventId', elementId);
            // eslint-disable-next-line
            const response = await axios({
                    method: 'put',
                    url: `${API_ROUTES.EVENTS}`,
                    data: bodyFormData,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                });
                getEvents();
                closeModal();
            }
            catch (error) {
                console.error('Error while sending text : ', error)
            }
    }
    
    // Tells what number to give to an image so it can be assigned a category
    function getCategoryForModal(modalContent) {
        switch (modalContent) {
            case 1:
                return 1; // Intro Image
            case 2:
                return 2; // Intro 2 Image
            case 3:
                return 3; // Slider Images
            case 4:
                return 4; // Gallery Images
            default:
                return 0; // Catégorie par défaut (si aucune catégorie spécifique n'est définie)
        }
    }

    useEffect(() => {
        getImages();
        getTexts()
        getPress()
        getEvents()
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const fetchData = async () => {
          setIsLoading(true);
          await Promise.all([getImages()]);
          setIsLoading(false);
        };
    
        fetchData();
        //eslint-disable-next-line
      }, []);
    
      if (isLoading) {
        return <Loading />;
      }

    return(
        <>
            <Header />
        <div className="home">
           <FaArrowCircleUp className="top-arrow" onClick={scrollToTop}/>
           <EditModal
            closeModal={closeModal} 
            openModal={openModal} 
            editImage={editImage}
            deleteImage={deleteImage}
            addImage={addImage}
            editTexts={editTexts}
            editPress={editPress}
            modalContent={modalContent} 
            setModalContent={setModalContent} 
            imageUrl={imageUrl} 
            setImageUrl={setImageUrl} 
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            category={getCategoryForModal(modalContent)}
            slideImage={slideImage}
            galleryImages={galleryImages}
            newText={newText}
            setNewText={setNewText}
            newTextBis={newTextBis}
            setNewTextBis={setNewTextBis}
            introTitleId={introTitleId}
            introSubId={introSubId}
            aboutTextId={aboutTextId}
            valuesTextId={valuesTextId}
            galTextId={galTextId}
            texts={texts}
            addEvent={addEvent}
            editEvents={editEvents}
            />

           <Intro token={token}
           setModalContent={setModalContent} 
           introImage={introImage} 
           secondIntroImage={secondIntroImage} 
           introTitle={introTitle} 
           introSub={introSub} 
           aboutText={aboutText} 
           valuesText={valuesText} 
           introTitleId={introTitleId} 
           introSubId={introSubId} 
           texts={texts}
           />
           <Gallery 
           token={token} 
           setModalContent={setModalContent} 
           galleryImages={galleryImages} 
           setOpenGalModal={setOpenGalModal} 
           texts={texts}
           openGalModal={openGalModal}
           elementId={elementId}
           setElementId={setElementId}
           closeModal={closeModal}
           />
           {/* <Slider token={token} setModalContent={setModalContent} slideImage={slideImage.map(slide => slide.url)}/> */}
           <Events presses={presses} events={events} token={token} setModalContent={setModalContent} setElementId={setElementId}/>
           <Contact />
        </div>
        </>

    )
}

export default Home