import './Contact.scss'
import asso from '../../assets/presse/asso.jpg'
import { GiPathDistance } from "react-icons/gi";
import { CiInstagram, CiMail } from "react-icons/ci";

function Contact(){

    return(
    <footer>
        <div className="contact" id='contact'>
            {/* <img src={roseraie} alt="" /> */}
            <div className="contact-content">
                <div className="contact-info">
                    <div className="text">
                        <div className='address'>
                            <span className='icon'>
                                <GiPathDistance/>
                            </span>
                            <span className='address-text'>46 rue de la fontaine de Jubert, 79160 Ardin</span>
                        </div>
                        <div className="mail">
                            <p>Pour une visite hors portes-ouvertes ou toute autre demande, nous contacter :</p>
                            <a className='mail-link' href='mailto:v.talon79@outlook.com'>
                                <span className='icon'>
                                    <CiMail/>
                                </span>
                                <span>v.talon79@outlook.com</span>
                            </a>
                        </div>
                        <div className="asso">
                            <span>Entrée gratuite, participation libre au profit de l'association locale "Pour Louis et Toi", venant en aide
                                aux enfants souffrants d'une maladie rare
                            </span>
                            <div className="asso-img">
                                <a href="https://www.facebook.com/p/Pour-Louis-et-Toi-100064793323062/?locale=fr_FR" target="blank">
                                    <img src={asso} alt="Affiche de l'association" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="access">
                    <div className="iframe-background">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2748.0956174492653!2d-0.5626534143234515!3d46.466613991517995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48072190b5b35a2d%3A0xd18460759e7e973f!2s46%20Rue%20de%20la%20Font%20de%20Jubert%2C%2079160%20Ardin!5e0!3m2!1sfr!2sfr!4v1714751355376!5m2!1sfr!2sfr"
                        allowFullScreen="" title='Accès aux jardins'>
                        </iframe>
                    </div>
                </div>
            </div>
            <div className="foot">
                <div className="foot-text">
                    <span>© All rights reserved - 2024 || &nbsp; </span>
                    <span>46 rue de la fontaine de jubert, 79160 Ardin || &nbsp; </span>
                    <div className="socials">
                    <a href="https://www.instagram.com/philip_puychereau/" target='blank' rel='noreferrer'>
                        <CiInstagram className='instagram' size={'2em'}/>
                    </a>
                    </div>
                </div>
                <div className="site-maker">
                    <a href="https://enzovincent.com" target='blank' rel='noreferrer'><span>Site conçu par Enzo Vincent, développeur Fullstack</span></a>
                </div>
            </div>
        </div>
    </footer>
    )
}

export default Contact